/* Ensure the hero section takes up full height */
.hero {
    position: relative;
    overflow: hidden;
    height: 100vh; /* Full viewport height */
  }
  
  /* Style for each slider item */
  .slider-item {
    position: relative;
    width: 100%;
    height: 100vh; /* Fixed height to cover full viewport */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Hide any overflow */
  }
  
  /* Overlay style for a darker background */
  .slider-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
  }
  
  /* Style for the image within the slider item */
  .slider-item .img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    min-height: 100%; /* Ensure it covers the height */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating of background image */
    transform: translate(-50%, -50%); /* Center the image */
    z-index: 0;
  }
  
  /* Ensure the text is above the image */
  .text {
    position: relative;
    color: #fff;
    z-index: 2;
    text-align: center; /* Center text */
    padding: 20px; /* Optional padding for spacing */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .slider-item .text {
      padding: 20px;
      text-align: center; /* Center text on smaller screens */
    }
  }
  