body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.imageaa {
  background-image: url("/src//Component//Header/bg_1.jpg");
}
.header-carousel .img-responsive {
  height: 100vh;
  object-fit: cover;
}

.menu_fixed {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: #fff !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0 15px;
  background: rgb(255 255 255 / 0.95) !important;
  padding: 10px 10px;
}

.one-third {
  height: 100vh !important;
}

.owl-carousel .owl-dots .owl-dot.active {
  background: #80cbc4;
}
.zoom-in {
  animation: zoom-in 0.5s ease;
}

@keyframes zoom-in {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
