/* General Styles */
.landing-container {
  background-color: #f7f8fc; /* Light background color */
  font-family: 'Arial', sans-serif;
  margin-top: 80px;
}

.hero-section {
  background-color: #4a90e2;
  color: white;
  padding: 50px 0;
  text-align: center;
  border-bottom: 2px solid #4a90e2;
  margin-top: 101px;
}

.headertext {
  color: #f7f8fc;
}

.hero-title {
  font-size: 3rem; 
  font-weight: bold;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.market-cards-section {
  padding: 50px 20px;
  text-align: center;
}

.market-owner-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.market-owner-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #ddd;
}

.market-owner-card:hover {
  transform: translateY(-5px); /* Lift card on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Increase shadow */
}

.market-owner-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.market-row {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}

.market-name {
  background-color: #ff6f61;
  color: white;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 0.95rem;
  transition: background-color 0.3s ease;
}

.market-name:hover {
  background-color: #4a90e2; /* Darker green on hover */
}

.no-markets {
  font-size: 1.2rem;
  color: #999;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.pagination-btn {
  background: linear-gradient(145deg, #007bff, #0056b3); /* Gradient effect */
  color: white;
  border: none;
  padding: 8px 16px; /* Moderate padding */
  margin: 0 5px;
  cursor: pointer;
  border-radius: 30px; /* Rounded button */
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.pagination-btn:hover {
  background: linear-gradient(145deg, #0056b3, #007bff); /* Reverse gradient on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08); /* Darker shadow */
}

.pagination-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  box-shadow: none; /* Remove shadow for disabled state */
}

.pagination-btn.active {
  background: linear-gradient(145deg, #ff6f61, #e04e3e); /* Active state with new gradient */
  box-shadow: 0 4px 8px rgba(255, 111, 97, 0.2); /* Subtle glow effect */
}

.pagination-btn:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.6); /* Focus state with glowing border */
}

@media (max-width: 768px) {
  .hero-section {
    padding: 40px 0;
  }

  .market-owner-list {
    grid-template-columns: 1fr 1fr;
  }

  .pagination-btn {
    padding: 6px 12px; /* Smaller padding for smaller screens */
    font-size: 0.95rem; /* Slightly smaller font size */
  }
}

@media (max-width: 480px) {
  .market-owner-list {
    grid-template-columns: 1fr;
  }
  
  .pagination-btn {
    padding: 5px 10px; /* Even smaller padding */
    font-size: 0.85rem; /* Smaller font size */
  }
}

.oval-shape {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 50px;  /* Makes it an oval shape */
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
}

.oval-shape:hover {
  background-color: #ff6f61; /* Darker shade on hover */
}
