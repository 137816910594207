Add this to your MarketOwnerDetail.css file

/* Overlay for the background image */
.card-header {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Dark overlay for text visibility */
    z-index: 0;
  }
  
  .card-header h1 {
    position: relative;
    z-index: 1;
    color: white;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .card-header img {
    border: 5px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 15px;
    z-index: 1;
  }
  
  .card-body {
    padding: 2rem;
  }
  
  .category-section ul,
  .subcategory-section ul {
    list-style: none;
    padding-left: 0;
  }
  
  .category-section li,
  .subcategory-section li {
    margin-bottom: 10px;
  }
  
  .category-section li strong,
  .subcategory-section li strong {
    font-size: 1.2rem;
    color: #333;
  }
  